import { useState, useEffect } from "react";
import * as React from "react";
import { StyleSheet } from "react-native";
import * as Yup from "yup";
import slugify from "slugify";

import { Form, FormikTextInputField, FormSection, VStack } from "@smartrent/ui";

import { FormikImageUpload } from "@/common/formik/FormikImageUpload";

import {
  organizationRequiredMessage,
  uploadcareConfig,
  uploadcarePublicKey,
} from "@/utils";

import { OrganizationProps } from "@/types";

import { LocationForm } from "../formik/LocationForm";

const alloyAdminRequiredArgs = [
  "create_alloy_install_account",
  "use_org_admin_as_alloy_install_admin",
];
const alloyAdminRequired = (ai_account_required: boolean, useOrg: boolean) =>
  ai_account_required || (ai_account_required && !useOrg);

const alloyAdminRequiredMessage = (field: string) =>
  `Please enter the Alloy Install admin's ${field}.`;

export const validationSchema = Yup.object({
  name: Yup.string().required(organizationRequiredMessage("name")),
  url: Yup.string().required(organizationRequiredMessage("URL")),
  logo_url: Yup.mixed().notRequired(),
  create_org_admin_user: Yup.boolean().notRequired(),
  org_admin_first_name: Yup.string().when("create_org_admin_user", {
    is: true,
    then: Yup.string().required(
      organizationRequiredMessage("admin user's first name")
    ),
  }),
  org_admin_last_name: Yup.string().when("create_org_admin_user", {
    is: true,
    then: Yup.string().required(
      organizationRequiredMessage("admin user's last name")
    ),
  }),
  org_admin_email_address: Yup.string().when("create_org_admin_user", {
    is: true,
    then: Yup.string()
      .email("Must be a valid email address")
      .required(organizationRequiredMessage("admin user's email address")),
  }),
  org_admin_phone_number: Yup.string().when("create_org_admin_user", {
    is: true,
    then: Yup.string().required(
      organizationRequiredMessage("admin user's phone number")
    ),
  }),
  alloy_install_admin_first_name: Yup.string().when(alloyAdminRequiredArgs, {
    is: alloyAdminRequired,
    then: Yup.string().required(alloyAdminRequiredMessage("first name")),
  }),
  alloy_install_admin_last_name: Yup.string().when(alloyAdminRequiredArgs, {
    is: alloyAdminRequired,
    then: Yup.string().required(alloyAdminRequiredMessage("last name")),
  }),
  alloy_install_admin_email_address: Yup.string().when(alloyAdminRequiredArgs, {
    is: alloyAdminRequired,
    then: Yup.string()
      .email("Must be a valid email address")
      .required(alloyAdminRequiredMessage("email")),
  }),
  alloy_install_admin_phone_number: Yup.string().when(alloyAdminRequiredArgs, {
    is: alloyAdminRequired,
    then: Yup.string().required(alloyAdminRequiredMessage("phone number")),
  }),
});

interface OrganizationFormProps {
  values: Partial<OrganizationProps>;
  setFieldValue: (field: string, value: any, v: any) => void;
}

export const OrganizationForm: React.FC<OrganizationFormProps> = ({
  values,
  setFieldValue,
}) => {
  const [organizationSlug, setOrganizationSlug] = useState(values.url);

  const handleOrganizationNameBlur = () => {
    if (values.url === "") {
      setOrganizationSlug(
        slugify(values.name || "", {
          lower: true,
        })
      );
    }
  };

  useEffect(() => {
    setFieldValue("url", organizationSlug, true);
  }, [organizationSlug, setFieldValue]);

  return (
    <Form>
      <FormSection title="Details" style={styles.drawerSection}>
        <VStack spacing={32}>
          <FormikTextInputField
            name="name"
            label="Organization Name"
            required
            textInputProps={{
              onBlur: handleOrganizationNameBlur,
            }}
          />
          <FormikTextInputField
            name="url"
            label="Organization URL"
            assistiveText={`e.g.\t${organizationSlug}.smartrent.com`}
            required
            textInputProps={{
              value: organizationSlug,
              onChangeText: setOrganizationSlug,
            }}
          />
        </VStack>
      </FormSection>
      <FormSection title="Upload Image" style={styles.drawerSection}>
        <FormikImageUpload
          displayImage
          alt="Organization Logo"
          name="logo_url"
          configuration={{
            ...uploadcareConfig,
            publicKey: uploadcarePublicKey,
            imagesOnly: "true",
          }}
        />
        <LocationForm title="Location" canEdit={true} requiredFields={false} />
      </FormSection>
    </Form>
  );
};

const styles = StyleSheet.create({
  drawerSection: { marginBottom: 24 },
});
