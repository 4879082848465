import * as React from "react";
import { Marker, Popup } from "react-leaflet";
import L from "leaflet";

// @todo update to SR icon
import defaultIconUrl from "leaflet/dist/images/marker-icon.png";

interface Props {
  position: { lat: number; lng: number };
  children?: React.ReactNode;
  divIconProps?: L.DivIconOptions;
  popupOptions?: L.PopupOptions;
}

export const SRMapMarker: React.FC<Props> = ({
  children,
  position,
  divIconProps,
  popupOptions,
}: Props) => {
  return (
    <Marker
      position={position}
      icon={
        L.divIcon({ ...divIconProps }) ||
        L.icon({
          iconUrl: defaultIconUrl,
          iconSize: [16, 24],
        })
      }
    >
      <Popup {...(popupOptions || {})}>{children}</Popup>
    </Marker>
  );
};
